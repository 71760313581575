import axios from 'axios'
import React, { memo, useCallback, useEffect, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { useDispatch, useSelector } from 'react-redux'
import { removeJwt, removeKeyChatBot, removeTokenRefresh, removeUser } from '../redux/actions/authentication'
import { hideRightBar } from '../redux/actions/rightbar-actions'
import { hideModal } from '../redux/actions/modal-actions'
import { Action } from '../redux/actions/actions'

export const LogoutTimer = memo(() => {
  const { auth: isAuthenticated } = useSelector((state: any) => state)
  const dispatch = useDispatch()
  const timeout = 1000 * 60 * 60
  const [, setRemaining] = useState(timeout)
  const [isIdle, setIsIdle] = useState(false)

  const handleOnActive = useCallback(() => setIsIdle(false), [])
  const handleOnIdle = useCallback(() => setIsIdle(true), [])

  const { getRemainingTime } = useIdleTimer({
    timeout,
    onActive: handleOnActive,
    onIdle: handleOnIdle,
    crossTab: true,
  })

  useEffect(() => {
    if (isIdle) {
      removeJwt()
      removeUser()
      removeTokenRefresh()
      localStorage.removeItem('ExecProfile')
      localStorage.removeItem('FeedProfile')
      dispatch(hideRightBar())
      dispatch(hideModal())
      dispatch({ type: Action.Unauthorized })
      localStorage.clear()
      // throwErrorMessage('Unauthorized')
    }
  }, [dispatch, isIdle])

  useEffect(() => {
    setRemaining(getRemainingTime())

    const timer = !isAuthenticated
      ? undefined
      : setInterval(() => {
          setRemaining(getRemainingTime())
        }, 1000)
    return () => clearInterval(timer as NodeJS.Timeout)
  }, [getRemainingTime, isAuthenticated])

  axios.interceptors.request.use(response => {
    if (response?.method === 'post' && response.data?.token === undefined && response.url === '/api/auth/refresh') {
      removeJwt()
      removeUser()
      removeTokenRefresh()
      localStorage.removeItem('ExecProfile')
      localStorage.removeItem('FeedProfile')
      dispatch(hideRightBar())
      dispatch(hideModal())
      dispatch({ type: Action.Unauthorized })
      localStorage.clear()
      // throwErrorMessage('Unauthorized')
      return
    }
    return response
  })
  return <></>
})
